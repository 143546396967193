import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import Pusher from 'pusher-js';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PusherService {
  pusher: any;
  channel: any;
  user: any;

  constructor() {
    this.pusher = new Pusher(environment.host.pusher.key, {
      authEndpoint: environment.baseUrl + '/api/socket_auth',
      auth: {
        headers: {
          Authorization: (localStorage.getItem(`${environment.panel}id_token`)) ? localStorage.getItem(`${environment.panel}id_token`) as any : "",
        }
      },
      cluster: environment.host.pusher.cluster
    })
    this.user = (localStorage.getItem(`${environment.panel}user`)) ? JSON.parse(localStorage.getItem(`${environment.panel}user`) as any) : ""
  }

  public userAcc = new BehaviorSubject("");
  userAccUpdate(): Observable<any> {
    this.user = (localStorage.getItem(`${environment.panel}user`)) ? JSON.parse(localStorage.getItem(`${environment.panel}user`) as any) : ""
    this.channel = this.pusher.subscribe(`private-user.${this.user.id}`);
    this.channel.bind('UserAccountUpdated', (e: any) => {
      this.userAcc.next(e);
    });

    // let data: any = { balance: 102100, expose: "0.00", msg: "", pl: "0.00", refresh: false, type: 4 }
    // this.userAcc.next(data);
    return this.userAcc.asObservable();
  }

  public globalMsg = new BehaviorSubject("");
  globalMsgUpdate(): Observable<any> {
    this.channel = this.pusher.subscribe(`other`);
    this.channel.bind('GlobalMessageUpdated', (e: any) => {
      this.globalMsg.next(e);
    });

    // let data: any = { "message": "cricket event which is being held behind closed doors in that if any players found to be taking advantage of groundline in fancy bets in such cases bets can be voided after the market ends . Company decision to be final ." }
    // this.globalMsg.next(data);
    return this.globalMsg.asObservable();
  }

  public exposer = new BehaviorSubject("");
  exposerUpdate(eId: any): Observable<any> {
    this.user = (localStorage.getItem(`${environment.panel}user`)) ? JSON.parse(localStorage.getItem(`${environment.panel}user`) as any) : ""

    this.channel = this.pusher.subscribe(`private-expose.${eId}.${this.user.id}`);
    console.log(this.user.expose);

    this.channel.bind('ExposeUpdated', (e: any) => {
      this.exposer.next(e);
    });

    // let data: any = { "op": "A", "exposes": [{ "event_id": 30989229, "user_id": 283, "market_id": "9.3759186016", "selection_id": "7900130989229", "market_type_id": "FANCY", "potential_profit": 1000, "potential_loss": -1000, "expose": -1000, "status": "1" }], "bets": { "fancy_bets": [{ "id": 1690061, "user_id": 283, "user_level": "1-2-14-283", "event_id": 30989229, "event_type_id": 4, "market_id": "9.3759186016", "bet_type": 0, "betfair_bet_id": null, "selection_id": "7900130989229", "runner_name": "10 Over OMN", "market_type_id": "FANCY", "betting_type": "F", "rate": "66.00", "average_rate_matched": "66.00", "price": "100.00", "stake": "1000.00", "stake_matched": "1000.00", "stake_cancelled": "0.00", "win": "1000.00", "lose": "1000.00", "result": 0, "commission_in": "0.00", "commission_in_amount": "0.00", "commission_out": "0.00", "commission_out_amount": "0.00", "adjustment_factor": "0.00", "status": 0, "is_matched": 1, "client_ip": "89.38.69.99", "client_browser": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/94.0.4606.71 Safari/537.36 Edg/94.0.992.38", "requested_at": "2021-10-11T09:40:48.000000Z", "matched_at": "2021-10-11T09:40:49.000000Z", "created_at": "2021-10-11T09:40:49.000000Z", "updated_at": "2021-10-11T09:40:49.000000Z", "deleted_at": null, "win_amount": 1000, "lose_amount": 1000, "stake_remaining": 0, "user": { "id": 283, "username": "Demo10", "is_coins": 0, "coin_postfix": "PTI", "available_balance": null } }], "fancy_bet_count": 0, "market_bets": [], "market_bet_count": 0 }, "bet_id": 1690061, "market_id": "9.3759186016" }
    // this.exposer.next(data);
    return this.exposer.asObservable();
  }

  public event = new BehaviorSubject("");
  eventUpdate(eId: any): Observable<any> {
    this.channel = this.pusher.subscribe(`event-${eId}`);
    this.channel.bind('EventUpdated', (e: any) => {
      this.event.next(e);
    });

    // let data: any = { "op": "UPDATE", "refresh": false }
    // this.event.next(data);
    return this.event.asObservable();
  }

  public eventList = new BehaviorSubject("");
  eventListUpdate(): Observable<any> {
    const channel = this.pusher.subscribe(`event-list`);
    channel.bind('EventListUpdated', (e: any) => {
      console.log(e);

      this.eventList.next(e);
    });

    // let data: any = { "op": "UPDATE" }
    // this.eventList.next(data);
    return this.eventList.asObservable();
  }

  unsubscribeChannel(channelName:any) {
    this.pusher.unsubscribe(channelName);
  }
}
