<div class="user-profile">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header" *ngIf="!isModal">
                <h4 class="mb-0 text-uppercase"><i class="mdi mdi-account"></i>Change password</h4>
            </div>
            <div class="row d-flex justify-content-start">
                <div class="col-md-10 col-lg-10 col-xl-4 change-password-col">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="change-details">
                                    <form [formGroup]="formData" (ngSubmit)="onSubmit()">
                                        <div class="form-group row">
                                            <label for="staticEmail" class="col-md-12 col-form-label">Old Password</label>
                                            <div class="col-md-12 position-relative">
                                                <input [type]="oldPasswordTextType ? 'text' : 'password'" class="form-control" formControlName="old_password" [ngClass]="{ 'is-invalid': submitted && f.old_password.errors }">
                                                <div *ngIf="submitted && f.old_password.errors" class="invalid-feedback">
                                                    <div *ngIf="f.old_password.errors.required">The Old Password field is required</div>
                                                </div>
                                                <span (click)="toggleOldPasswordFieldTextType()" class="mdi field-icon toggle-password" [ngClass]="{'mdi-eye': !oldPasswordTextType, 'mdi-eye-off': oldPasswordTextType }"></span>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="staticEmail" class="col-md-12 col-form-label">New Password</label>
                                            <div class="col-md-12 position-relative">
                                                <input [type]="newPasswordTextType ? 'text' : 'password'" class="form-control" formControlName="new_password" [ngClass]="{ 'is-invalid': submitted && f.new_password.errors }">
                                                <div *ngIf="submitted && f.new_password.errors" class="invalid-feedback">
                                                    <div *ngIf="f.new_password.errors.required">The New Password field is required</div>
                                                    <div *ngIf="f.new_password.errors.minlength">The New Password field must be at least
                                                        6 characters
                                                    </div>
                                                    <div *ngIf="!f.new_password.errors.minlength && f.new_password.errors.pattern">The
                                                        New Password must
                                                        contain at least: 1 uppercase letter, 1 lowercase letter, 1 number
                                                    </div>
                                                </div>
                                                <span (click)="toggleNewPasswordFieldTextType()" class="mdi field-icon toggle-password" [ngClass]="{'mdi-eye': !newPasswordTextType, 'mdi-eye-off': newPasswordTextType}"></span>
                                            </div>
                                        </div>
                                        <div class="form-group row mb-3">
                                            <label for="staticEmail" class="col-md-12 col-form-label">Confirm Password</label>
                                            <div class="col-md-12 position-relative">
                                                <input [type]="confirmPasswordTextType ? 'text' : 'password'" class="form-control" formControlName="new_password_re" [ngClass]="{ 'is-invalid': submitted && f.new_password_re.errors }">
                                                <div *ngIf="submitted && f.new_password_re.errors" class="invalid-feedback">
                                                    <div *ngIf="f.new_password_re.errors.required">The Confirm Password field is
                                                        required</div>
                                                    <div *ngIf="f.new_password_re.errors.mustMatch">The Confirm Password confirmation
                                                        does not match
                                                    </div>
                                                </div>
                                                <span (click)="toggleConfirmPasswordFieldTextType()" class="mdi field-icon toggle-password" [ngClass]="{ 'mdi-eye': !confirmPasswordTextType, 'mdi-eye-off': confirmPasswordTextType }"></span>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-md-12">
                                                <button type="submit" class="btn btn-block btn-login w-100">Submit</button>
                                                <!-- <button type="submit" class="btn btn-warning custom-gradient border-radius">Submit</button> -->
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>