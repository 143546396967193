import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { Router } from "@angular/router";
import { CommonService } from "src/app/@shared/services/common.service";
import { environment } from "src/environments/environment";

@Injectable()
export class AuthGuard implements CanActivate {
  env = environment;
  constructor(public router: Router, public _common: CommonService) { }

  canActivate() {
    if (localStorage.getItem(`${this.env.panel}id_token`) && localStorage.getItem(`${this.env.panel}user`)) {
      return true;
    }
    // this._common.removeLocalstorage();
    this.router.navigate(["/home"]);
    // this.router.navigate(["/login"]);
    return false;
  }
}

@Injectable()
export class RedirectGuard implements CanActivate {
  env = environment;
  constructor(public router: Router, public _common: CommonService) { }

  canActivate() {
    if (this.env.guestEnable) {
      this.router.navigate(["/home"]);
    } else {
      this.router.navigate(["/home"]);
      //this.router.navigate(["/login"]);
    }
    return true;
  }
}
