import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError, of } from "rxjs";
import { catchError, retry, map, tap } from "rxjs/operators";
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { MessageService } from "../message/message.service";
import { IbaseService } from "./ibase-service";
import { environment } from "src/environments/environment";

export abstract class BaseService<T> implements IbaseService<any> {
  public httpOptions() {
    return {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": (localStorage.getItem(`${environment.panel}id_token`)) ? localStorage.getItem(`${environment.panel}id_token`) as any : "",
      }),
    };
  }
  public httpOptions1() {
    return {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": (localStorage.getItem(`${environment.panel}id_token`)) ? localStorage.getItem(`${environment.panel}id_token`) as any : "",
      }),
    };
  }

  public httpOptionsWithUrlEncoded() {
    return {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": (localStorage.getItem(`${environment.panel}id_token`)) ? localStorage.getItem(`${environment.panel}id_token`) as any : "",
      }),
    };
  }

  constructor(public http: HttpClient, public _message: MessageService) { }

  Get(url: any): Observable<any> {
    return this.http.get(url, this.httpOptions()).pipe(
      // retry(1),
      catchError(this.handleError)
    );
  }

  Post(url: any, para: any): Observable<any> {
    return this.http.post(url, para, this.httpOptions()).pipe(
      // retry(1),
      catchError(this.handleError)
    );
  }

  PostWithUrlEncoded(url: any, para: any): Observable<any> {
    return this.http.post(url, para, this.httpOptionsWithUrlEncoded()).pipe(
      // retry(1),
      catchError(this.handleError)
    );
  }

  login(url: any, para: any): Observable<any> {

    return this.http.post(url, para, this.httpOptions1()).pipe(
      // retry(1),
      catchError(this.handleError)
    );
  }

  Put(url: any, para: any): Observable<any> {
    return this.http.put(url, para, this.httpOptions()).pipe(
      // retry(1),
      catchError(this.handleError)
    );
  }

  Delete(url: any): Observable<any> {
    return this.http.delete(url, this.httpOptions()).pipe(
      // retry(1),
      catchError(this.handleError)
    );
  }

  htmlResponse(url: any, para: any): Observable<any> {
    let authToken = (localStorage.getItem(`${environment.panel}id_token`)) ? localStorage.getItem(`${environment.panel}id_token`) as any : "";
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8');
    headers = headers.append('Authorization', authToken);
    const requestOptions: Object = {
      headers: headers,
      responseType: 'text'
    }
    if (para != '') {
      return this.http.post<any>(url, para, requestOptions);
    } else {
      return this.http.get<any>(url, requestOptions);
    }
  }

  public handleError(error: HttpErrorResponse) {
    console.log(error.status);
    if (error.status == 401 || error.status == 403) {

      // remove localstorage
      const items = { ...localStorage };
      Object.entries(items).forEach(([key, value]) => {
        if (key.includes(environment.panel)) {
          localStorage.removeItem(key)
        }
      });
      window.location.reload();
    }
    return throwError(error);
  }
}
